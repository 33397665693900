<template>
  <div style="padding: 10px">
    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
      <el-tab-pane label="本人实测爬塔阵容" name="towerFormation">
        <tower-formation ref="towerFormation"/>
      </el-tab-pane>
      <el-tab-pane label="网络搜集爬塔阵容" name="towerOtherFormation">
        <tower-other-formation ref="towerOtherFormation"/>
      </el-tab-pane>
      <el-tab-pane label="推图阵容" name="levelFormation">
        <level-formation ref="levelFormation"/>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>

  import levelFormation from "@/views/saltedFish/levelFormation";
  import towerFormation from "@/views/saltedFish/towerFormation";
  import towerOtherFormation from "@/views/saltedFish/towerOtherFormation";

  export default {
    name: 'formation',
    components: {levelFormation, towerFormation, towerOtherFormation},
    data() {
      return {
        activeName: 'towerFormation',
      }
    },
    methods:{
      handleClick(tab, event) {

      }
    }
  }
</script>
